
    import React from 'react';

    const ApiURl = "http://localhost/ecommere_react_with_php-main/src/ajax";
    // const ApiURl = "https://bhe.algo2botsinfotech.com/ajax";










    export default   ApiURl ;