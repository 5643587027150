


const imgLocation = "http://localhost/ecommere_react_with_php-main/src/ajax/images";
// const ApiURl = "https://bhe.algo2botsinfotech.com/ajax";










export default   imgLocation ;